import React from 'react';
import { Link } from 'react-router-dom';
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import Footer from '../../components/footer';
import './usageGuide.scss';
import TopBar from '../../components/navbar/topbar';
import config from '../../../config';

class UsageGuide extends React.Component {

    //TODO literales

    render() {

        return (
            <React.Fragment>
                <GradientNav active="guide" {...this.props} />
                <div className="usage-guide-wrapper">
                    <TopBar {...this.props} />
                    <div className="inner">
                        <h1>Guía de uso de Yogabot</h1>
                        <p>Yogabot es un sistema de <b>Autodiagnóstico Corporal y Recomendación de Práctica de Yoga</b> a medida.</p>
                        <p>El Autodiagnóstico Corporal se realiza en base al nivel de tono y elasticidad de las principales zonas corporales, desde el apartado <Link to="/home"> DIAGNÓSTICO</Link>.</p> 
                        <p>A través del Diagnóstico Corporal, Yogabot completará tu Matriz Biométrica, la cual refleja tus niveles actuales de:</p>
                        <ul>
                            <li>ELASTICIDAD MEDIA</li>
                            <li>TONO MUSCULAR MEDIO</li>
                            <li>EQUILIBRIO CORPORAL MEDIO (TONO VS.ELASTICIDAD)</li>
                            <li>POTENCIA CORPORAL MEDIA</li>
                        </ul>
                        <p>Esta Matriz se representará de forma gráfica en la silueta humana, con un código de colores que muestran el estado de desequilibrio entre Tono y Elasticidad de cada zona corporal (<Link to="/home/guide/option/2">Ver código de colores</Link>).
                        </p>
                        <p>Con todos estos indicadores, el Algoritmo Matemático de Secuenciación de Yogabot diseñará una secuencia de Práctica de Yoga Personal y Óptima para trabajar sobre los principales desequilibrios de tu Matriz Biométrica. 
                        </p>
                        <p>Dicha secuencia se irá optimizando y personalizando en la medida que vayas completando tu diagnóstico. Ver el apartado de <Link to="/practiceList">PRÁCTICA</Link>
                        </p>
                        <p><b>¿Cómo Completar el Diagnóstico?</b></p>
                        <p>Todo el proceso de Diagnóstico está dividido en 6 Retos, cada uno de los cuales se componen de una serie de ejercicios de medición de Tono y elasticidad, por cada zona corporal.
                        </p>
                        <p>Los 6 retos están balanceados y ordenados de forma tal que, si se hacen todos de una vez, es como realizar una secuencia de práctica neutral de una hora, aproximadamente.
                        </p>
                        <p>En cada uno de los ejercicios de cada reto, tendrás un video que explica claramente cómo realizar el ejercicio para que el resultado sea lo más preciso posible. Además, cuentas con un formulario para marcar el Rango de Tono o elasticidad más adecuado a ti.
                        </p>
                        <p>En la medida que vayas completando cada reto, podrás ver como tu Matriz Biométrica se actualiza y se va completando una Secuencia de Práctica optimizada para ti… 
                        </p>
                        <p><b>¿Cómo sacar el máximo rendimiento de Yogabot?</b>
                        </p>
                        <p><b>PRIMERO</b>: Mantén tu Matriz Biométrica ACTUALIZADA al 100%. Esto significa que cuando hayas practicado más de 25 horas con tu Secuencias Optimizada por Yogabot, o hayan pasado 2 meses sin una práctica regular de Yoga, tu Matriz Biométrica puede esta desactualizada… Vuelve a completar tu Diagnóstico Corporal
                        </p>
                        <p><b>SEGUNDO</b>: Practica todo el tiempo que puedas con tu Secuencia Optimizada. Con Yogabot tienes la opción de adaptar tu secuencia al tiempo real del que dispones para tu práctica personal, en cada momento… Para ello solo debes seleccionar el tipo de secuencia: Corta, Media o Larga, que te aparecerá en el apartado de <Link to="/practiceList">PRÁCTICA</Link>
                        </p>
                        <p>Si quieres saber algo más puedes visitar nuestra página de  <Link to="/faq">preguntas frecuentes</Link></p>
                        <div className="video-guide-wrapper">
                            <div className="v-ratio">
                                <div className="iframe-content">
                                    <iframe title="vimeo" src={config.player.path + window.btoa("https://player.vimeo.com/video/413554776?color=E0684B&title=0&byline=0&portrait=0")} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
                <SubMenu active="guide" {...this.props}/>
            </React.Fragment>
        );

    }

}
export default UsageGuide;