// Third party libraries
import _ from 'lodash';
import React from 'react';
import Fullscreen from 'fullscreen-react';

// Components
import FixedBanner from '../../../components/fixedBanner';
import Footer from '../../../components/footer';
import GradientNav from '../../../components/navbar/gradient';
import TopBar from '../../../components/navbar/topbar';
import SubMenu from '../../../components/navbar/submenu';
import Notification from '../../../components/notificaction';
import PlayerButtons from '../components/playerButtons';
import PlayerTimer from '../components/playerTimer';
import NextSlide from '../components/nextSlide';
import Preambule from '../components/preambule';
import ProgressBarSeeker from '../components/progressBarSeeker';
import { Responsive } from 'semantic-ui-react';

// Utils
import UseNoSleep from '../../../../utils/noSleep';
import { TrackingService } from '../../../../utils/TrackingService';
import { changeScreenOrientation, completeSession, showHealthAdvise, onRestart } from '../../../../utils/practice';

// Models
import SessionModel from '../../../../data/models/session/session';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../practice.scss';

class PracticeImage extends React.Component {

    intervalCountDown = 0;
    song = null;
    language = window.atob(this.props.match.params.sequence).split('/')[1];
    startOnPortrait = false;

    backgroundAudio = null;

    constructor(props) {

        super(props);

        this.state = {
            isFull: false,
            currentSlideIndex: 0,
            data: null,
            slideElapsedTime: 0,
            startTimeSlide: 0,
            firstStart: false,
            globalProgress: 0,
            globalSequenceTime: 0,
            showPreambule: false,
            showNextSlide: false,
            status: 'idle',
            startTimePreambule: 0,
            sequence: '',
            userSessionId: '',
            indications: {
                flexoextensionIndicator: 0,
                lateralizationIndicator: 0,
                abductionAductionIndicator: 0,
                muscularTrainIndicator: 0
            },
            indexAsanasRealized: new Set([])
        };

    }

    componentWillUnmount() {

        clearInterval(this.intervalCountDown);
        clearInterval(window['yogabotIntervalsPracticePreambule']);
        delete window['yogabotIntervalsPracticePreambule'];
        this.props.closeGenericModal();
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);
        
        if(this.backgroundAudio) {
            this.backgroundAudio.pause();
            this.backgroundAudio.currentTime = 0;
            this.backgroundAudio.src = '';
            this.backgroundAudio = null;
        }

    }

    componentDidMount() {

        let globalSequenceTime = 0;
        _.each(this.props.data.routine, routine => {

            globalSequenceTime += _.parseInt(routine.duration);

        });

        const indications = {
            flexoextensionIndicator: this.props.bioMetricMatrix.flexoextensionIndicator || 0,
            lateralizationIndicator: this.props.bioMetricMatrix.lateralizationIndicator || 0,
            abductionAductionIndicator: this.props.bioMetricMatrix.abductionAductionIndicator || 0,
            muscularTrainIndicator: this.props.bioMetricMatrix.muscularTrainIndicator || 0
        };

        this.song = new Audio(this.props.data.routine[0].nameAudio);

        this.setState({
            data: this.props.data,
            sequence: this.props.sequence,
            globalSequenceTime,
            indications
        });

        showHealthAdvise(this.props.sessionTime, this.props.openGenericModal, this.props.closeGenericModal);

        this.selectNatureBackgroundAudio();

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.backgroundAudio, this.props.backgroundAudio)) {
            this.selectNatureBackgroundAudio();
        }

    }

    selectNatureBackgroundAudio = () => {

        if(this.props.backgroundAudio === 'nature') {
            // Se ponen los volúmenes independientes para cada sonido porque puede que alguno se escuche menos que otro por defecto
            // para poder equilibrar el sonido
            const naturalSound = {
                surf: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/surf.mp3',
                    volume: 0.2,
                },
                waterfall: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/waterfall.mp3',
                    volume: 0.1,
                },
                water: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/water.mp3',
                    volume: 0.3,
                },
                birds: {
                    src: 'https://s3.eu-west-1.amazonaws.com/s3-yogabot-app/s3-yogabot-app/practices/audio/birds.mp3',
                    volume: 0.1,
                },
            };

            const keys = Object.keys(naturalSound);
            const randomKey = keys[Math.floor(Math.random() * keys.length)];
            const randomValue = naturalSound[randomKey];

            try {
                this.backgroundAudio = new Audio();
                this.backgroundAudio.src = randomValue.src;
                this.backgroundAudio.loop = true;
                this.backgroundAudio.volume = randomValue.volume;
                this.backgroundAudio.play();
            } catch (error) {
                console.error(error);
            }       
        }

    }

    selectInstructionsBackgroundAudio = (index = 0) => {
        setTimeout(()=>{
            if(this.props.backgroundAudio === 'instructions'){
                try {
                    if(this.backgroundAudio){
                        this.backgroundAudio.pause();
                        this.backgroundAudio.currentTime = 0;
                        this.backgroundAudio.src = '';
                        this.backgroundAudio = null;
                    }
        
                    this.backgroundAudio = new Audio();
                    this.backgroundAudio.src = this.props.data.routine[index].technicalAudio;
                    this.backgroundAudio.loop = false;
                    this.backgroundAudio.volume = 1;
                    this.backgroundAudio.play();
                } catch (error) {
                    console.error(error);
                }

            }
        }, 600)
    }

    tickCountDown = () => {

        this.setState({ slideElapsedTime: this.state.data.routine[this.state.currentSlideIndex].duration - (Math.floor((Date.now() - this.state.startTimeSlide) / 1000)) });

        if (this.state.slideElapsedTime <= 0) {

            let self = this;
            let nextAsanaIndex = this.state.currentSlideIndex;
            nextAsanaIndex++;

            if (nextAsanaIndex < this.state.data.routine.length) {

                clearInterval(self.intervalCountDown);

                let globalProgress = this.state.globalProgress;

                // Esto es para que un asana que ya se realizó no vuelva a sumar al progreso de la práctica
                let indexAsanasRealized = this.state.indexAsanasRealized;

                if(!indexAsanasRealized.has(this.state.currentSlideIndex)) {

                    globalProgress = globalProgress + (this.state.data.routine[this.state.currentSlideIndex].duration - this.state.slideElapsedTime);
                    
                    indexAsanasRealized.add(this.state.currentSlideIndex);
                } 
                
                this.setState({
                    globalProgress,
                    currentSlideIndex: nextAsanaIndex,
                    showNextSlide: false,
                    slideElapsedTime: 0,
                    showPreambule: true,
                    startTimePreambule: Date.now(),
                    indexAsanasRealized
                }, async () => {

                    try {

                        SessionModel.onAsanaFinish({ userSessionId: this.state.userSessionId, timeElapsed: this.state.data.routine[this.state.currentSlideIndex-1].duration }).then(() => {

                            this.props.getLastWeekTrainingSeconds();

                        });

                    } catch (error) {

                        console.error('error:onAsanaFinish', error);

                    }

                });

            } else {

                this.state.indexAsanasRealized.add(nextAsanaIndex);

                if(this.state.indexAsanasRealized.size === this.state.data.routine.length){
                    this.setState({ globalProgress: this.state.globalSequenceTime, showNextSlide: false, slideElapsedTime: 0, status: 'idle', showPreambule: false });
                    clearInterval(this.intervalCountDown);
                    completeSession(this.state.userSessionId, this.props.history, this.language, '/practice/result/sequence', this.props.getLastWeekTrainingSeconds);
                } else {
                    const globalProgress = this.state.globalProgress + (this.state.data.routine[this.state.currentSlideIndex].duration - this.state.slideElapsedTime);

                    this.setState({ globalProgress, slideElapsedTime: this.state.data.routine[0].duration });
                    this.onForwardAndBackward(0);
                    this.onPause();
                }

            }

        } else {

            if (_.get(this.state.data.routine, '[' + (this.state.currentSlideIndex + 1) + '].duration', false) && (this.state.slideElapsedTime <= 10)) {

                if (!this.state.showNextSlide) {

                    if (this.state.data.routine[this.state.currentSlideIndex].duration > 10) {

                        this.song.src = 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/ES/next.mp3';
                        this.song.addEventListener('ended', () => {

                            this.song = this.song.cloneNode(true);
                            this.song.src = this.state.data.routine[this.state.currentSlideIndex + 1].nameAudio;
                            this.song.play();

                        }, false);

                        if(this.backgroundAudio){
                            this.backgroundAudio.volume = 0.2;
                        }

                        this.song.play();

                    } else if (this.state.data.routine[this.state.currentSlideIndex].duration >= 5) {

                        this.song.src = this.state.data.routine[this.state.currentSlideIndex + 1].nameAudio;
                        this.song.play();

                    }

                    this.setState({ showNextSlide: true });

                }

            }

        }

    }

    startCountDown = () => {

        this.setState({ showPreambule: false });

        if (this.state.currentSlideIndex < this.state.data.routine.length) {

            this.setState({ status: 'playing', slideElapsedTime: this.state.data.routine[this.state.currentSlideIndex].duration, startTimeSlide: Date.now() });
            this.intervalCountDown = setInterval(this.tickCountDown, 1000);

        }

    }

    restart = () => {

        this.setState({ firstStart: false, globalProgress: 0, currentSlideIndex: 0, showNextSlide: false, slideElapsedTime: 0, status: 'idle', showPreambule: false, isFull: false });
        clearInterval(this.intervalCountDown);

    }

    onStart = async () => {
        TrackingService.registerEvent('Practice', 'practiceSequenceStart');

        try {

            const response = await SessionModel.startSession({ sequence: this.state.sequence });

            this.song = new Audio(this.props.data.routine[this.state.currentSlideIndex].nameAudio);
            this.song.play();

            this.setState({ userSessionId: response.data._id, firstStart: true, isFull: true, showPreambule: true, startTimePreambule: Date.now() });
            this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

        } catch (error) {

            console.error('error:onStart:', error);

        }

    }

    onPause = () => {

        clearInterval(this.intervalCountDown);
        this.setState({ status: 'resume', isFull: false });
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

    }

    onResume = () => {
        
        let lastTime = this.state.slideElapsedTime;

        this.setState({ isFull: true, status: 'playing', startTimeSlide: Math.floor(Date.now() - ((this.state.data.routine[this.state.currentSlideIndex].duration - lastTime) * 1000)) });
        this.intervalCountDown = setInterval(this.tickCountDown, 1000);
        this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

    }

    gotoDiscoverMore = () => this.props.history.push(`/practiceDiscover/targets/${ this.props.match.params.sequence }`);

    onForwardAndBackward = (index) => {
        this.setState({currentSlideIndex: index, showNextSlide: false});
    }

    render() {

        const { isFull } = this.state;
        const { isAlternative, windowMeassures: { height } } = this.props;

        return (
            <React.Fragment>
                <UseNoSleep />
                <GradientNav active='practice' { ...this.props } />
                { this.state.data ? (
                    <div className='wrapper-practice'>
                        <TopBar { ...this.props } callback={ () => this.props.history.push('/practiceList') } text={ I18n.t('practice.practice') } />
                        <FixedBanner />
                        <div className='inner'>
                            { !isAlternative &&
                                <div className='header-message'>
                                    <div className='header-content'>
                                        <div className='left'>
                                            { this.props.bioMetricMatrix.progress > 0 ?
                                                <p dangerouslySetInnerHTML={{ __html: I18n.t('practice.welcomeMessage', { userName: _.get(this.props,'userName', '') }) }}></p> :
                                                <p dangerouslySetInnerHTML={{ __html: I18n.t('practice.welcomeMessageAlternative', { userName: _.get(this.props,'userName', '') }) }}></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <Fullscreen isEnter={ isFull } onChange={ isFull => this.setState({ isFull }) }>
                                <div className={ 'wrapper-slides ' + (isFull ? 'fullscreen-enabled' : '') } >
                                    <div style={{ width: '100%', maxWidth: isFull ? `${height * (16 / 9)}px` : '100%' }}>
                                        <div className='wrapper-slide' >
                                            <Preambule
                                                visible={ this.state.showPreambule }
                                                startTimePreambule={ this.state.startTimePreambule }
                                                callback={ ()=>{
                                                    this.startCountDown();
                                                    this.selectInstructionsBackgroundAudio(this.state.currentSlideIndex);
                                                } }
                                            />
                                            <div className={ 'content ' + (this.state.showPreambule ? 'blurred': '') }>
                                                <PlayerButtons
                                                    visible={ !this.state.showPreambule }
                                                    status={ this.state.status }
                                                    firstStart={ this.state.firstStart }
                                                    onStart={ this.onStart }
                                                    onRestart={ () => onRestart(this.props.openGenericModal, this.props.closeGenericModal, this.onPause, this.restart, this.onResume) }
                                                    onPause={ this.onPause }
                                                    onResume={ this.onResume }
                                                />
                                                <PlayerTimer
                                                    visible={ !this.state.showPreambule }
                                                    slideElapsedTime={ this.state.slideElapsedTime }
                                                />
                                                <img className='img-slider' src={ this.state.data.routine[this.state.currentSlideIndex].image } alt=''></img>
                                                <NextSlide
                                                    visible={ this.state.showNextSlide }
                                                    currentSlideIndex={ this.state.currentSlideIndex }
                                                    routine={ this.state.data.routine }
                                                />
                                                {
                                                    isFull && 
                                                        <>
                                                            <Responsive minWidth={805} as={React.Fragment}>
                                                                <ProgressBarSeeker
                                                                    globalProgress={ this.state.globalProgress }
                                                                    globalSequenceTime={ this.state.globalSequenceTime }
                                                                    videos={this.state.data?.routine}
                                                                    startLimit={0.07}
                                                                    endLimit={0.15}
                                                                    startLimitPercent={15}
                                                                    endLimitPercent={80}
                                                                    styles={{top: '92%', zIndex: 20}}
                                                                    onForwardAndBackward={this.onForwardAndBackward}
                                                                />
                                                            </Responsive>
                                                            <Responsive maxWidth={804} as={React.Fragment}>
                                                                <ProgressBarSeeker
                                                                    globalProgress={ this.state.globalProgress }
                                                                    globalSequenceTime={ this.state.globalSequenceTime }
                                                                    videos={this.state.data?.routine}
                                                                    startLimit={0.07}
                                                                    endLimit={0.07}
                                                                    startLimitPercent={15}
                                                                    endLimitPercent={80}
                                                                    styles={{top: '88%', zIndex: 20}}
                                                                    onForwardAndBackward={this.onForwardAndBackward}
                                                                />
                                                            </Responsive>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fullscreen>
                            <ProgressBarSeeker
                                globalProgress={ this.state.globalProgress }
                                globalSequenceTime={ this.state.globalSequenceTime }
                                videos={this.state.data?.routine}
                                startLimit={0.07}
                                endLimit={0.07}
                                startLimitPercent={15}
                                endLimitPercent={80}
                                onForwardAndBackward={this.onForwardAndBackward}
                            />
                        </div>
                        <div className='footer-separator'></div>
                        <Footer { ...this.props } type='branded'/>
                    </div>
                ) : '' }
                <SubMenu active='practice' { ...this.props } />
                <Notification type='fixered' />
            </React.Fragment>
        );

    }

}

export default PracticeImage;