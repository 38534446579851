// Third party libraries
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faLock, faUser, faMoneyBillAlt, faLink } from '@fortawesome/free-solid-svg-icons';

// Utils
import { hasOneYearPaymentPromotion } from '../../../../utils/checkPromotions';
import history from '../../../../history';

// Locales
import I18n from '../../../../i18n';

// Styles
import './menuProfile.scss';

// Assets
import PlaceholderImage from '../../../assets/img/yogabot_icon.svg';

const MenuProfile = props => {

    const closeSession = () => {

        if (window.localStorage.getItem('jwt')) {

            window.localStorage.removeItem('jwt');

        }

        history.push('/auth/login');

    }

    const { role, userSession, active } = props;
    const roles = _.get(role || {}, 'role', []);

    const canShowPayment = () => !role.hasActiveSubscription &&
        (!roles.includes('premium') ||
            (role.premiumExpirationDate !== 'NOT_EXPIRE' && moment(role.premiumExpirationDate).startOf('day').diff(moment().startOf('day'), 'days') < 30));

    return (
        <div className='sticked'>
            <div className='p-avatar'>
                <div className='p-image'>
                    { userSession?.pictureUrl ?
                        <img crossOrigin='anonymous' src={ userSession.pictureUrl || PlaceholderImage } alt='' onError={ e => e.target.src = PlaceholderImage } /> :
                        <i className='f-icon-icono_14'></i>
                    }
                </div>
                <span className='p-name'>{ userSession.email }</span>
            </div>
            <div className='p-menu-items'>
                <span className={ `item ${ active === 'account' ? 'active' : '' }` }><Link to='/profile/account'><FontAwesomeIcon icon={ faUser } />{ I18n.t('menu.datas') }</Link></span>
                <span className={ `item ${ active === 'security' ? 'active' : '' }` }><Link to='/profile/security'><FontAwesomeIcon icon={ faLock } />{ I18n.t('menu.password') }</Link></span>
                <span className={ `item ${ active === 'suscription' ? 'active' : '' }` }><Link to='/profile/suscription'><i style={{ marginLeft: '-5px', fontSize: '17px', marginRight: '5px' }} className='f-icon-suscripcion'></i>{ I18n.t('menu.mySuscription') }</Link></span>
                { canShowPayment() && <span className={ `item ${ active === 'plans' ? 'active' : '' }` }><Link to='/profile/plans'><FontAwesomeIcon icon={ faMoneyBillAlt } />{ I18n.t('menu.plans') }</Link></span> }
                <span className={ `item ${ active === 'objectives' ? 'active' : '' }` }><Link to='/profile/objectives'><Icon name='chart line' />{ I18n.t('menu.objectives') }</Link></span>
                { canShowPayment() && hasOneYearPaymentPromotion() && <span className={ `item ${ active === 'promotion' ? 'active' : '' }` }><Link to='/profile/promotion'><i className='f-icon-ticket'></i>{ I18n.t('menu.promotions') }</Link></span> }
                {/* <span className={ `item ${ active === 'wallet' ? 'active' : '' }` }><Link to='/profile/wallet'>{ pendingOperations && <SideBardBudget hasPendings /> }<FontAwesomeIcon icon={ faWallet } />{ I18n.t('menu.wallet') }</Link></span> */}
                <span className='item'><Link to='/profile/wallet/reflink'><FontAwesomeIcon icon={ faLink } />{ I18n.t('wallet.linkRef') }</Link></span>
                <span className='item' onClick={ closeSession }><FontAwesomeIcon icon={ faSignOutAlt } />{ I18n.t('menu.closeSession') }</span>
            </div>
        </div>
    );

}

export default MenuProfile;