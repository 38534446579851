// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// Components
import GradientNav from '../../../../components/navbar/gradient';
import SubMenu from '../../../../components/navbar/submenu';
import TopBar from '../../../../components/navbar/topbar';
import Footer from '../../../../components/footer';
import ResultChallenges from '../../../../components/diagnosis/components/challenges/result';

// Utils
import { TrackingService } from '../../../../../utils/TrackingService';

// Redux
import { updateChallengeAvailibility } from '../../../../../redux-store/challenges';
import { openGenericModal, closeGenericModal } from '../../../../../redux-store/genericModal';
import { getBiometricMatrix } from '../../../../.../../../redux-store/bioMetricMatrix';
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { getChallenges } from '../../../../../redux-store/challenges';
import { getRole } from '../../../../../redux-store/role';
import { getCustomSessionStats } from '../../../../../redux-store/customSessionStats';

// Models
import SecurityModel from '../../../../../data/models/security/security';

// Config
import Config from '../../../../../config';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './result.scss';

class DiagnosisChallengeResult extends React.Component {


    async componentDidMount() {

        try {

            await this.props.getCustomSessionStats();

        } catch (error) {

        }

    }

    backToMeasurement = () => {

        this.props.history.push(`/diagnosis/challenge/${this.props.match.params.challengeId}`);

    }

    completeGrowChallenges = async () => {

        try {

            await SecurityModel.updateGrowChallengeStatus('finished');
            await this.props.getRole();

        } catch (error) {

        } finally {

            this.props.history.push(`/home`);

        }

    }

    sendNotificationToPro = async () => {
        try {
            const proInfo = await SecurityModel.getProFullInfo();

            const {userSession: { email }} = this.props;

            if(_.size(proInfo) === 0) return;

            const proUsers = [];

            _.map(proInfo, data => {
                if(data?.customTracking === true){
                    proUsers.push(data._id);
                }
            });
            
            if(_.size(proUsers) === 0) return;

            const platforms = ['Web', 'Android', 'iOS'];

            const title = I18n.t('messages.userUpdateMBTitle');

            const body = I18n.t('messages.userUpdateMB', { email });

            await SecurityModel.sendNotificationToPros({ title, body, proUsers, platforms });
        } catch (error) {
            console.error(error)
        }
    }

    updateMatriz = async () => {

        const { userSession, role, openLoader, closeLoader, closeGenericModal, updateChallengeAvailibility, getBiometricMatrix, match, getChallenges, history, openGenericModal, customSessionStats } = this.props;
        const { completeGrowChallenges } = this;

        try {

            openLoader();

            await this.sendNotificationToPro();

            await updateChallengeAvailibility({challengeId: match.params.challengeId});
            await getBiometricMatrix();

            if (match.params.challengeId === '5') {

                await getChallenges();

            }

        } catch (error) {

            closeLoader();

        } finally {

            setTimeout(() => {

                closeLoader();

                if (match.params.challengeId === '5') {

                    TrackingService.registerEvent('BiometricMatrix', 'completeBiometricMatrix');

                    if (_.get(role, 'attributes.growChallenge', false) && role.attributes.growChallenge === 'explorer') {

                        //paso 2 completado por defecto
                        const completedGrowChallenges = [];
                        completedGrowChallenges[1] = 2;

                        (userSession.name && userSession.surname) ? completedGrowChallenges[0] = 1 : completedGrowChallenges[0] = 0;
                        (customSessionStats.userCustomSessionsCount || 0) >= Config.growChallenge.customPractices ? completedGrowChallenges[2] = 3 : completedGrowChallenges[2] = 0;

                        //possible output [0,2,3] || [0,2,0] || [1,2,0] || [1,2,3]

                        //para saber a donde mandarle tengo que obtener la primera ocurrencia del 0, que es el paso que está sin terminar

                        const nextStepToCompleteIndex = completedGrowChallenges.indexOf(0); // possible output -1,0,1,2

                        //El paso 2 nunca tendrá 0, porque es en el paso que estamos..
                        const actionsOfNextStepToComplete = {

                            //Paso 1 sin completar
                            0 : {
                                text: I18n.t('actions.completeProfile'),
                                callback: ()=> {

                                    history.push('/profile/account')

                                }

                            },
                            //Paso 3 sin completar
                            2 : {

                                text: I18n.t('actions.doPractice'),
                                callback: ()=> history.push('/practiceList')

                            }

                        }

                        //Si no hay ningún 0, es decir todos completados
                        if (nextStepToCompleteIndex === -1 ) {

                            //llamar a finished porque tiene los 3 pasos del modo crecimiento completados
                            completeGrowChallenges();

                        }

                    } else {

                        history.push(`/home`);

                    }

                } else {

                    history.push(`/home`);

                }

            }, 2500);

        }

    }

    render() {

        const _challenge = +this.props.match.params.challengeId + 1;
        const { match, userSession } = this.props;

        return (
            <React.Fragment>
                <GradientNav active="diagnosis" {...this.props}/>
                <div className="result-wrapper">
                    <TopBar {...this.props} text={I18n.t('challenge.challengeEnd', {challenge : _challenge})}/>
                    <ResultChallenges  userSession={userSession} challengeId={match.params.challengeId} onBackToMeasurement={this.backToMeasurement} onUpdateMatriz={this.updateMatriz} />
                    <Footer { ...this.props } type="branded" />
                </div>
                <SubMenu active="diagnosis" {...this.props}/>
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state,'auth.userSession', {}),
        role: _.cloneDeep(_.get(state,'role', {})),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        customSessionStats: _.get(state, 'customSessionStats', {})

    };
};

export default connect(mapStateToProps, { getCustomSessionStats, getRole, openGenericModal, closeGenericModal, updateChallengeAvailibility, getBiometricMatrix, openLoader, closeLoader, getChallenges })(DiagnosisChallengeResult);