// Third party libraries
import _ from 'lodash';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faLock, faUser, faMoneyBillAlt, faLink } from '@fortawesome/free-solid-svg-icons'

// Utils
import { hasOneYearPaymentPromotion } from '../../../../utils/checkPromotions';

// Redux Store
import { getRole } from '../../../../redux-store/role';
import { getTransactionMovements } from '../../../../redux-store/walletTransactions';

// History
import history from '../../../../history';

// Locales
import I18n from '../../../../i18n';

// Styles
import './sideBarProfile.scss';

export default function sideBarProFileHoc (WrappedComponent, active) {

    class HOC extends React.Component {

        constructor (props) {

            super (props);

            this.state = {
                expanded : false
            };

        }

        async componentDidMount() {

            try {

                await this.props.getTransactionMovements();

            } catch (error) {

                console.error(error);

            }

        }

        onToggle = () => this.setState(prevState => ({ expanded: !prevState.expanded }));

        closeSession = () => {

            history.push('/auth/login');

            if (window.localStorage.getItem('jwt')) {

                window.localStorage.removeItem('jwt');

            }

        }

        render() {

            const { expanded } = this.state;
            const { role, userSession } = this.props;
            const roles = _.get(role || {}, 'role', []);

            const canShowPayment = () => !role.hasActiveSubscription &&
                (!roles.includes('premium') ||
                    (role.premiumExpirationDate !== 'NOT_EXPIRE' && Moment(role.premiumExpirationDate).startOf('day').diff(Moment().startOf('day'), 'days') < 30));

            return <>
                <div className={ 'side-bar-profile ' + (expanded ? 'expanded' :  '') }>
                    <span className='sp-close' onClick={ this.onToggle }><i className='f-icon-cancel'></i></span>
                    <div className='sp-avatar'>
                        <div className='sp-image'>
                            { userSession.pictureUrl ? <img crossOrigin='anonymous' src={ userSession.pictureUrl } alt='' /> : <i className='f-icon-icono_14'></i> }
                        </div>
                        <span className='sp-name'>{userSession.email}</span>
                    </div>
                    <div className='sp-menu-items'>
                        <span className={ `item ${ active === 'account' ? 'active' : '' }` }><Link to='/profile/account'><FontAwesomeIcon icon={ faUser } />{ I18n.t('menu.datas') }</Link></span>
                        <span className={ `item ${ active === 'security' ? 'active' : '' }` }><Link to='/profile/security'><FontAwesomeIcon icon={ faLock } />{ I18n.t('menu.password') }</Link></span>
                        <span className={ `item ${ active === 'suscription' ? 'active' : '' }` }><Link to='/profile/suscription'><i style={{ marginLeft: '-5px', fontSize: '17px', marginRight: '5px' }} className='f-icon-suscripcion'></i>{ I18n.t('menu.mySuscription') }</Link></span>
                        { canShowPayment() && <span className={ `item ${ active === 'plans' ? 'active' : '' }` }><Link to='/profile/plans'><FontAwesomeIcon icon={ faMoneyBillAlt } />{ I18n.t('menu.plans') }</Link></span> }
                        <span className={ `item ${ active === 'objectives' ? 'active' : '' }` }><Link to='/profile/objectives'><Icon name='chart line' />{ I18n.t('menu.objectives') }</Link></span>
                        { canShowPayment() && hasOneYearPaymentPromotion() && <span className={ `item ${ active === 'promotion' ? 'active' : '' }` }><Link to='/profile/promotion'><i className='f-icon-ticket'></i>{ I18n.t('menu.promotions') }</Link></span> }
                        {/* <span className={ `item ${ active === 'wallet' ? 'active' : '' }` }><Link to='/profile/wallet'>{ pendingOperations && <SideBardBudget hasPendings /> }<FontAwesomeIcon icon={ faWallet } />{ I18n.t('menu.wallet') }</Link></span> */}
                        <span className='item'><Link to='/profile/wallet/reflink'><FontAwesomeIcon icon={ faLink } />{ I18n.t('wallet.linkRef') }</Link></span>
                        <span className='item' onClick={ this.closeSession }><FontAwesomeIcon icon={ faSignOutAlt } />{ I18n.t('menu.closeSession') }</span>
                    </div>
                </div>
                { expanded && <div className='dimmered' onClick={ this.onToggle }></div> }
                <WrappedComponent onToggle={ this.onToggle } { ...this.props }/>
            </>;

        }

    }

    const mapStateToProps = state => {

        return {
            userSession: _.cloneDeep(_.get(state,'auth.userSession', {})),
            role: _.cloneDeep(_.get(state,'role', {})),
            currency: _.get(state, 'auth.userSession.config.currency', {}),
            bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
            customSessionStats: _.get(state, 'customSessionStats', {}),
            pendingOperations: state?.walletTransactions?.pendingOperations
        };

    }

    return connect(mapStateToProps, { getRole, getTransactionMovements })(HOC);

}