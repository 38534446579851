// Third party libraries
import { Modal, TextArea } from 'semantic-ui-react';
import { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { closeLoader, openLoader } from '../../../redux-store/loader';

// Models
import SecurityModel from '../../../data/models/security/security';

// Components
import OButton from '../../styled/components/button';

// Locales
import I18n from '../../../i18n';

// Styles
import './sendMessageModal.scss';

const SendMessageModal = ({ open, proUserId, onClose }) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');

  const handleSendMessageSubmit = async () => {
    try {
      onClose();
      dispatch(openLoader());

      await SecurityModel.sendMessageToPro(proUserId, { message });

      setMessage('')
      dispatch(closeLoader());
    } catch (e) {
      dispatch(closeLoader());
    }
  };

  return (
    <Modal open={open || false} className="send-message-modal" size="tiny">
      <Modal.Header className="top-header-block">
        <span>{I18n.t('messages.sendMessage')}</span>
        <i className="f-icon-cancel" onClick={onClose}></i>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className="description-block">
          <strong>{I18n.t('practice.whatYouWantToSay')}</strong>
        </Modal.Description>
        <TextArea
          placeholder={I18n.t('practice.messagePlaceholder')}
          style={{
            minHeight: '112px',
            width: '100%',
            borderColor: '#F4F4F4',
            padding: '10px',
            resize: 'none',
          }}
          onChange={(event) => setMessage(event?.target.value)}
          value={message}
        />
        <Modal.Actions className="wrapper-info-btn">
          <OButton
            primary
            type="button"
            fluid
            upper
            onClick={handleSendMessageSubmit}
          >
            <span>{I18n.t('messages.sendMessage')}</span>
          </OButton>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default SendMessageModal;
